import React, { useEffect } from 'react';

const PricingTable = () => {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/pricing-table.js';
      script.async = true;
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  
    return (
      <div >
        <stripe-pricing-table
          pricing-table-id="prctbl_1PfIadEgjuEPpB0vqBvECm8s"
          publishable-key="pk_live_51PDn0bEgjuEPpB0vsnnybCsp6SwmrQ4KUMlLqUe29Gze7pUKDb4OyU632h40ekRTJ1wqw8pN1eR4o5WhMadecNUL00tAkqXr5q"
        ></stripe-pricing-table>
      </div>
    );
  };
  
  export default PricingTable;