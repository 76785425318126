import React, { useState, useEffect, useRef } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import LogOut from './logOut';


const AppBar = () => {
  const [authUser, setAuthUser] = useState(null);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [isHelpPopupVisible, setHelpPopupVisible] = useState(false);
  const [isNotifBoxVisible, setNotifBoxVisible] = useState(false); // Nouvel état pour NotifBox
  const helpButtonRef = useRef(null);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const fetchUserData = async (userId) => {
      try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          setAuthUser({
            name: userDoc.data().username || 'User',
            email: userDoc.data().email,
          });
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        if (error.code === 'permission-denied') {
          console.error('Permission denied. Check Firestore rules.');
        } else {
          console.error('Error fetching user data:', error);
        }
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserData(user.uid);
      } else {
        setAuthUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const toggleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  const toggleHelpPopup = () => {
    setHelpPopupVisible(!isHelpPopupVisible);
  };

  const toggleNotifBox = () => {
    setNotifBoxVisible(!isNotifBoxVisible);
  };

  return (
    <div className="zeheader">
      <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Tweny Logo" />
      <div className="user-info">
        <div className="notification">
          <div className="icon" onClick={toggleHelpPopup} ref={helpButtonRef}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7D4EDA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-help-circle">
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M9.09 9a3 3 0 1 1 3.82 4.58l-.82.82a1.5 1.5 0 0 0 2.13 2.13"></path>
              <line x1="12" y1="17" x2="12.01" y2="17"></line>
            </svg>
          </div>
          <div className="icon" onClick={toggleNotifBox}> 
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7D4EDA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell">
              <path d="M18 8a6 6 0 0 0-12 0c0 7-3 9-3 9h18s-3-2-3-9"></path>
              <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>
          </div>
        </div>
        {authUser && (
          <>
            <div className="user-initials" onClick={toggleDropdown}>
              {authUser.name.split(' ').map((n) => n[0]).join('')}
            </div>
            <div className={`user-dropdown ${dropdownActive ? 'active' : ''}`} id="userDropdown">
              <div className="user-infoo">
                <div className="name">{authUser.name}</div>
                <div className="email">{authUser.email}</div>
              </div>
              <LogOut />
            </div>
          </>
        )}
      </div>
      {isHelpPopupVisible && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={toggleHelpPopup}>&times;</span>
            <p>Contact us at <a href="mailto:hello@twenymusic.com">hello@twenymusic.com</a></p>
          </div>
        </div>
      )}
      {isNotifBoxVisible &&(
    <div className="notif-box">
      <div className="notif-content">
        <span className="closerbouton" onClick={toggleNotifBox}>&times;</span>
          Centre de notifications
      </div>
    </div>
  )}
    </div>
  );
};

export default AppBar;