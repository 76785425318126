// Article.js

import React from "react";
import "../CssFiles/Article.css";

const Article = ({ title, content }) => {
  return (
    <div className="article-container">
      <h2>{title}</h2>
      <p>{content}</p>
    </div>
  );
};

export default Article;
