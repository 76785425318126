import React from "react";
import "../CssFiles/logo.css";
import { useNavigate } from "react-router-dom";

function Logo() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/dasboard");
  };

  return (
    <>
      <div className="logo-container">
        <a onClick={handleNavigate} >
          <img className="logo-image" src="../logo.png" alt="Logo" />
        </a>
      </div>
      <p className="intro">PROTECT<br/>SYNC<br/> & COLLECT</p>
    </>
  );
}

export default Logo;
