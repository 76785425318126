import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, updateProfile } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../CssFiles/profil.css';
import { FaSpotify } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { getSpotifyAuthURL, getSpotifyFollowers, getYouTubeAuthURL, getYouTubeSubscribers, updateUserInFirestore } from '../api'; // Mettez à jour le chemin en fonction de la structure de votre projet

function ProfileContainer() {
  const [user, setUser] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [profilePicture, setProfilePicture] = useState('');
  const [spotifyFollowers, setSpotifyFollowers] = useState(null);
  const [youtubeSubscribers, setYoutubeSubscribers] = useState(null);
  const navigate = useNavigate();
  const fileInputRef = React.createRef();
  const handleArticleClick = () => {
    navigate("/addarticle");
  };

  useEffect(() => {
    const firebaseAuth = getAuth();
    const fetchUser = async () => {
      onAuthStateChanged(firebaseAuth, async (user) => {
        if (user) {
          const { email, photoURL, emailVerified, uid } = user;
          const app = firebaseAuth.app;
          const firestore = getFirestore(app);
          const userDocRef = doc(firestore, 'users', uid);
          const userDocSnapshot = await getDoc(userDocRef);
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            const updatedUserData = {
              email,
              photoURL,
              emailVerified,
              uid,
              username: userData.username,
              userlastname: userData.userlastname,
              profilePicture: userData.profilePicture,
              addressPostal: userData.addressPostal,
              cellPhone: userData.cellPhone,
            };
            setUser(updatedUserData);
            setProfilePicture(userData.profilePicture);
            localStorage.setItem('userData', JSON.stringify(updatedUserData));
            if (!imagePreview) {
              const storedImagePreview = localStorage.getItem('imagePreview');
              setImagePreview(storedImagePreview);
            }

            // Check for Spotify and YouTube tokens in local storage
            const spotifyAccessToken = localStorage.getItem('spotifyAccessToken');
            if (spotifyAccessToken) {
              const spotifyFollowerCount = await getSpotifyFollowers(spotifyAccessToken);
              setSpotifyFollowers(spotifyFollowerCount);
            }

            const youtubeAccessToken = localStorage.getItem('youtubeAccessToken');
            if (youtubeAccessToken) {
              const youtubeSubscriberCount = await getYouTubeSubscribers(youtubeAccessToken);
              setYoutubeSubscribers(youtubeSubscriberCount);
            }
          }
        } else {
          setUser(null);
          setProfilePicture('');
          localStorage.removeItem('userData');
        }
      });
    };
    fetchUser();
  }, []);

  const handleSpotifyLogin = () => {
    const authUrl = getSpotifyAuthURL();
    window.location.href = authUrl;
  };

  const handleYouTubeLogin = () => {
    const authUrl = getYouTubeAuthURL();
    window.location.href = authUrl;
  };

  // Check for OAuth redirections
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const params = new URLSearchParams(hash.substring(1));
      const accessToken = params.get('access_token');
      const state = params.get('state');

      const firebaseAuth = getAuth();
      const user = firebaseAuth.currentUser;

      if (state === 'spotify' && accessToken && user) {
        localStorage.setItem('spotifyAccessToken', accessToken);
        window.location.hash = '';
        getSpotifyFollowers(accessToken).then(followers => {
          setSpotifyFollowers(followers);
          updateUserInFirestore(user.uid, { spotifyAccessToken: accessToken, spotifyFollowers: followers });
        });
      }

      if (state === 'youtube' && accessToken && user) {
        localStorage.setItem('youtubeAccessToken', accessToken);
        window.location.hash = '';
        getYouTubeSubscribers(accessToken).then(subscribers => {
          setYoutubeSubscribers(subscribers);
          updateUserInFirestore(user.uid, { youtubeAccessToken: accessToken, youtubeSubscribers: subscribers });
        });
      }
    }
  }, []);

  const handleImageChange = async (e) => {
    const selectedImage = e.target.files[0];
    const imagePreviewURL = URL.createObjectURL(selectedImage);
    setImagePreview(imagePreviewURL);
    localStorage.setItem('imagePreview', imagePreviewURL);
    
    try {
      const firebaseAuth = getAuth();
      const user = firebaseAuth.currentUser;
      if (user) {
        const newProfilePicture = imagePreviewURL; // Changez cette ligne pour utiliser l'URL de l'image téléversée
        await updateProfile(user, { photoURL: newProfilePicture });
        updateUserInFirestore(user.uid, { profilePicture: newProfilePicture });
        setProfilePicture(newProfilePicture);
      }
    } catch (error) {
      console.error('Error updating profile picture:', error);
    }
  };

  return (
    <div className="profile-container">
      <br />
      <br />
      <br />
      <div className="profile-header">
        <img
          className="profile-image"
          src={profilePicture || user?.photoURL}
          alt="Profile"
          onClick={() => fileInputRef.current.click()}
        />
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
        <h2 className="username">{user?.username}</h2>
        <h3 className="userlastname">{user?.userlastname}</h3>
        <p className="handle">@{user?.username}</p>
      </div>
      <div className="profile-stats">
        {spotifyFollowers === null ? (
          <div className="stat" onClick={handleSpotifyLogin}>
            <FaSpotify size={50} color="#1DB954" />
            <span className="stat-label">Connect to Spotify</span>
          </div>
        ) : (
          <div className="stat">
            <span className="stat-value">{spotifyFollowers}</span>
            <span className="stat-label">Spotify Followers</span>
          </div>
        )}
        {youtubeSubscribers === null ? (
          <div className="stat" onClick={handleYouTubeLogin}>
            <FaYoutube size={50} color="#FF0000" />
            <span className="stat-label">Connect to YouTube</span>
          </div>
        ) : (
          <div className="stat">
            <span className="stat-value">{youtubeSubscribers}</span>
            <span className="stat-label">YouTube Subscribers</span>
          </div>
        )}
      </div>
      <br/>
      <div className="button-container">
                <button onClick={handleArticleClick} className="addbutton">
                  Add article
                </button>
      </div>
    </div>
  );
}

export default ProfileContainer;