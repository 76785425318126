import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase.js";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Modal from "react-modal";
import "../CssFiles/component.css";

const truncateContent = (content, maxLength) => {
  return content.length > maxLength
    ? content.substring(0, maxLength) + "..."
    : content;
};

const ArticlesCarousel = () => {
  const [articles, setArticles] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      const articlesCollection = collection(firestore, "articles");
      const articleSnapshot = await getDocs(articlesCollection);
      const articlesList = articleSnapshot.docs.map((doc) => doc.data());
      setArticles(articlesList);
    };

    fetchArticles();
  }, []);

  const openModal = (article) => {
    setSelectedArticle(article);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedArticle(null);
  };

  return (
    <div className="carousel-wrapper">
      <Carousel showArrows={true} infiniteLoop={true} showThumbs={false}>
        {articles.map((article, index) => (
          <div key={index}>
            <h2>{article.title}</h2>
            {article.subtitle && <h3>{article.subtitle}</h3>}
            {article.imageUrl && (
              <div className="carousel-image">
                <img src={article.imageUrl} alt={article.title} />
              </div>
            )}
            <p>{truncateContent(article.content, 100)}</p>
            {article.content.length > 100 && (
              <button onClick={() => openModal(article)}>Learn More</button>
            )}
          </div>
        ))}
      </Carousel>
      {selectedArticle && (
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
          <h2>{selectedArticle.title}</h2>
          {selectedArticle.subtitle && <h3>{selectedArticle.subtitle}</h3>}
          {selectedArticle.imageUrl && (
            <div className="modal-image">
              <img src={selectedArticle.imageUrl} alt={selectedArticle.title} />
            </div>
          )}
          <p>{selectedArticle.content}</p>
          <button onClick={closeModal}>Close</button>
        </Modal>
      )}
    </div>
  );
};

export default ArticlesCarousel;
