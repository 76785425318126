import React, { useEffect, useState } from "react";
import firebase from "../firebase";
import "../CssFiles/songList.css";
import EditSongPopup from "./EditSongPopup"; // Import the popup component

function SongList() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState([true, true, true, true]);
  const [filteredList, setFilteredList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(5);
  const [selectedSong, setSelectedSong] = useState(null);

  useEffect(() => {
    const unsubscribeAuth = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        getSongs(user.uid);
      } else {
        setList([]);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  useEffect(() => {
    const filteredList = list.filter((song) => {
      const songTitle = song["Song Title"] || "";
      return songTitle.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredList(filteredList);
  }, [searchQuery, list]);

  function getSongs(userId) {
    setLoading(true);
    const userSongsCollection = firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .collection("Songs");

    const userDocRef = firebase.firestore().collection("users").doc(userId);
    userDocRef
      .get()
      .then((userDocSnapshot) => {
        if (userDocSnapshot.exists) {
          const userData = userDocSnapshot.data();
          const username = userData.username;

          userSongsCollection.onSnapshot((querySnapshot) => {
            const songs = [];
            querySnapshot.forEach((doc) => {
              const songData = doc.data();
              const song = {
                id: doc.id,
                ...songData,
                createdAt: songData.createdAt ? songData.createdAt.toDate() : new Date(),
                username,
              };
              songs.push(song);
            });
            setList(songs);
            setLoading(false);
          });
        } else {
          console.error("User data not found");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error getting user data:", error);
        setLoading(false);
      });
  }

  function handleSearch(event) {
    const query = event.target.value;
    setSearchQuery(query);
  }

  function sortTable(n) {
    const sortedList = [...filteredList].sort((a, b) => {
      const x = getColumnValue(a, n);
      const y = getColumnValue(b, n);
      if (n === 2) {
        const dateX = new Date(a["createdAt"]);
        const dateY = new Date(b["createdAt"]);
        return sortOrder[n] ? dateX - dateY : dateY - dateX;
      } else {
        if (x < y) return sortOrder[n] ? -1 : 1;
        if (x > y) return sortOrder[n] ? 1 : -1;
        return 0;
      }
    });
    setSortOrder((prevSortOrder) => {
      const newSortOrder = [...prevSortOrder];
      newSortOrder[n] = !newSortOrder[n];
      return newSortOrder;
    });
    setFilteredList(sortedList);
  }

  function getColumnValue(song, index) {
    switch (index) {
      case 0:
        return song["Song Title"] ? song["Song Title"].toLowerCase() : "";
      case 1:
        return song.username ? song.username.toLowerCase() : "";
      case 2:
        return song["createdAt"];
      case 3:
        return song.Status ? song.Status.toLowerCase() : "";
      default:
        return "";
    }
  }

  function handlePageClick(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function handleResultsPerPageClick(resultsNumber) {
    setResultsPerPage(resultsNumber);
    setCurrentPage(1);
  }

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = filteredList.slice(indexOfFirstResult, indexOfLastResult);
  const totalPages = Math.ceil(filteredList.length / resultsPerPage);

  const paginationButtons = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationButtons.push(
      <span
        key={i}
        className={`pagination-page ${currentPage === i ? "active" : ""}`}
        onClick={() => handlePageClick(i)}
      >
        {i}
      </span>
    );
  }

  function handleEditSong(id, updatedSong) {
    const user = firebase.auth().currentUser;
    if (user) {
      const songRef = firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("Songs")
        .doc(id);

      songRef
        .update(updatedSong)
        .then(() => {
          setList((prevList) =>
            prevList.map((song) =>
              song.id === id ? { ...song, ...updatedSong } : song
            )
          );
        })
        .catch((error) => {
          console.error("Error updating song:", error);
        });
    }
  }

  if (loading) {
    return <h1>Loading...</h1>;
  }

  return (
    <>
      <div className="songListContainer">
        <div className="contenue-container">
          <h2>Catalog</h2>
          <p>
            Here is the list of titles you have submitted to Tweny.
            <br />
            Tweny ensures the submission of these titles worldwide to guarantee optimal protection and collection.
            <br />
            The registration status is regularly updated.
          </p>
          <div className="action-container">
            <a href="#" className="register-work-btn" onClick={() => window.location.href = '/registeronesong'}>Register work</a>
            <div className="search-container">
              <input
                type="text"
                className="search-bar"
                placeholder="Search"
                onKeyUp={handleSearch}
              />
              <button className="search-btn">Search</button>
            </div>
          </div>
        </div>
        <br />
        <div className="songTableContainer">
          <table id="songTable">
            <thead>
              <tr>
                <th onClick={() => sortTable(0)}>
                  Title <span className="sort-icon"></span>
                </th>
                <th onClick={() => sortTable(1)}>
                  Songwriter(s) <span className="sort-icon"></span>
                </th>
                <th onClick={() => sortTable(2)}>
                  Date Added <span className="sort-icon"></span>
                </th>
                <th onClick={() => sortTable(3)}>
                  Status <span className="sort-icon"></span>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentResults.map((song) => (
                <tr key={song.id} className="song-row" onClick={() => setSelectedSong(song)}>
                  <td className="song-title">
                    <a href="#">{song["Song Title"]}</a>
                  </td>
                  <td>{song.username}</td>
                  <td>{song.createdAt.toDateString()}</td>
                  <td className={`status ${song.Status ? song.Status.toLowerCase() : ""}`}>{song.Status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          <div className="pagination">
            <span className="pagination-button" onClick={() => handlePageClick(1)}>FIRST</span>
            <span className="pagination-button" onClick={() => handlePageClick(currentPage > 1 ? currentPage - 1 : 1)}>&lt;</span>
            {paginationButtons}
            <span className="pagination-button" onClick={() => handlePageClick(currentPage < totalPages ? currentPage + 1 : totalPages)}>&gt;</span>
            <span className="pagination-button" onClick={() => handlePageClick(totalPages)}>LAST</span>
          </div>
          <div className="results-per-page">
            <span>Results Per Page:</span>
            {[5, 10, 25].map((number) => (
              <span
                key={number}
                className={`results-option ${resultsPerPage === number ? "active" : ""}`}
                onClick={() => handleResultsPerPageClick(number)}
              >
                {number}
              </span>
            ))}
          </div>
        </div>
      </div>
      {selectedSong && (
        <EditSongPopup
          song={selectedSong}
          onSave={handleEditSong}
          onClose={() => setSelectedSong(null)}
        />
      )}
    </>
  );
}

export default SongList;