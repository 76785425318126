import React, { useEffect, useState } from 'react';
import { auth } from "../firebase.js";
import { useNavigate } from "react-router-dom";

function EmailVerification() {
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const interval = setInterval(async () => {
      await auth.currentUser.reload();
      if (auth.currentUser.emailVerified) {
        clearInterval(interval);
        setIsVerified(true);
        navigate('/payment'); // Rediriger vers la page de paiement
      }
    }, 3000); // Vérifie toutes les 3 secondes

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [navigate]);

  return (
    <div>
      <h1>Please verify your email</h1>
      <p>We have sent a verification email to your address. Please verify and wait...</p>
      {isVerified && <p>Email verified! Redirecting to payment...</p>}
    </div>
  );
}

export default EmailVerification;