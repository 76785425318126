import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Carouselles from './thirdcaroussel';
import "../CssFiles/secondpage.css";
import SocialFollow from "./SocialFollow"


function Carouselle() {

  const imageLink = "https://maximejacquard.substack.com/p/sync-and-sound-quality?utm_source=profile&utm_medium=reader2";
  const slideStyle = {
    color: 'black',
    fontWeight: '900',
    fontFamily: 'Montserrat, sans-serif', 
    fontSize: '3.25vw',
    textAlign: 'center',
    padding: '50px',
    height: '150px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <>
    <div style={slideStyle}>
      We protect your songs, collect your royalties, and sync your creations
    </div>
     <div className="carousel-container">
     <Carousel className="w-50">
       <Carousel.Item>
         <a href={imageLink} target="_blank" rel="noopener noreferrer">
           <img
             className="d-block w-75"
             src="/images/article.jpeg"
             alt="First slide"
           />
         </a>
         <Carousel.Caption>
         </Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img
           className="d-block w-75 carousele-img"
           src="/images/royalties.jpeg"
           alt="Second slide"
         />
         <Carousel.Caption>
         </Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img
           className="d-block w-75"
           src="/images/collab.jpeg"
           alt="Third slide"
         />
         <Carousel.Caption>
           </Carousel.Caption>
       </Carousel.Item>
     </Carousel>

     <Carouselles /> 
   </div>
   </>
  );
}

export default Carouselle;
