import React from 'react';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const LogOut = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut()
      .then(() => {
        alert('Logout successful');
        localStorage.removeItem('userData');
        navigate('/');
        if (onLogout) {
          onLogout();
        }
      })
      .catch((error) => {
        console.error('Logout error', error);
      });
  };

  return (
    <a href="#" onClick={handleLogout}>Log out</a>
  );
};

export default LogOut;
