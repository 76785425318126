import React, { useState } from "react";
import "../CssFiles/AddArticle.css";
import { useNavigate } from "react-router-dom";
import { firestore } from "../firebase.js"; // Make sure the path is correct
import { collection, addDoc } from "firebase/firestore";
import { useAuth } from "./AuthContext"; // Import the AuthContext

const AddArticle = ({ onAddArticle }) => {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState(""); // New state for subtitle
  const [content, setContent] = useState("");
  const [imageUrl, setImageUrl] = useState(""); // New state for image URL
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth(); // Access the authentication status

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isAuthenticated) {
      navigate("/signup"); // Redirect to signup page if not authenticated
      return;
    } else {
      try {
        await addDoc(collection(firestore, "articles"), {
          title,
          subtitle, // Include subtitle in the document
          content,
          imageUrl, // Include image URL in the document
          createdAt: new Date(),
        });
        console.log(
          `Title: ${title}, Subtitle: ${subtitle}, Content: ${content}, Image URL: ${imageUrl}`
        );
        onAddArticle({ title, subtitle, content, imageUrl });
        // Reset form fields
        setTitle("");
        setSubtitle(""); // Reset subtitle field
        setContent("");
        setImageUrl(""); // Reset image URL field
        navigate("/article");
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  return (
    <div className="add-article-container">
      <div className="video-container">
        <video className="background-video" autoPlay loop muted>
          <source src="/Videos/tweny.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="form-container">
        <h2>Add New Article</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Subtitle:</label>
            <input
              type="text"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Image URL:</label>
            <input
              type="text"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              required
            />
          </div>
          <div className="form-group textarea-container">
            <label>Content:</label>
            <div>
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="button-container">
            <button type="submit" className="submitbutton">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddArticle;
