import React, { useState } from "react";
import "../CssFiles/register.css";
import { useNavigate } from "react-router-dom";
import Logo from "./logo";
import { auth } from "../firebase.js";
import AdminPage from "./adminPage";

async function loginUser(credentials, navigate) {
  try {
    const { username, password } = credentials;

    const signInMethods = await auth.fetchSignInMethodsForEmail(username);

    if (signInMethods.includes("password")) {
      await auth.signInWithEmailAndPassword(username, password);
      console.log("Login successful!");

      navigate("/dashboard");
    } else {
      alert(
        "The account does not exist or does not support password-based login."
      );
      navigate("/"); // Navigate to the account creation page
    }
  } catch (error) {
    alert(
      "The account does not exist or does not support password-based login."
    );
    navigate("/"); // Navigate to the account creation page
    throw error;
  }
}

function Register() {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [validation, setValidation] = useState("");
  const [showList, setShowList] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await loginUser({ username, password }, navigate);
    } catch (error) {
      console.error("Error submitting the form:", error);
    }

    if (username.length < 2 && password.length < 8) {
      setValidation("Error");
      return;
    }
  };

  const handleClickList = () => {
    setShowList(true);
  };

  return (
    <>
      {username === "alhousori2020@yahoo.com" && password === "admin" ? (
        <>
          <AdminPage />
        </>
      ) : (
        <>
          <Logo />
          <video className="back" autoPlay loop muted playsInline>
            <source src="/Videos/tweny.mp4" type="video/mp4" />
          </video>

          <form
            className="form_maine"
            onSubmit={handleSubmit}
            onClick={handleClickList}
          >
            <p className="login">Login</p>
            <div className="inputContainer">
              <label className="labelStyle">Email</label>

              <input
                // placeholder="Email"
                id="username"
                required={true}
                className="inputFields"
                // type="text"
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="inputContainer">
              <label className="labelStyle">Password</label>
              <input
                // placeholder="Password"
                id="password"
                required={true}
                className="inputFields"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button id="buttonlogin">Log in</button>
            <div className="passwordf">
              <a href="/password-recover" className="forgot">
                Forgot your password?
              </a>
              <hr class="hr"></hr>
            </div>
            <div className="signupContainer">
              Don't have an account?
              <a href="/signup">Register</a>
            </div>
          </form>
        </>
      )}
    </>
  );
}

export default Register;
