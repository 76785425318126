// src/ClubCard.js
import React from 'react';
import '../CssFiles/clubcard.css'; 

const ClubCard = ({ logo, title, description }) => {
  return (
    <div className="club-card">
      <img src={logo} alt={`${title} logo`} className="club-logo" />
      <h2 className="club-title">{title}</h2>
      <p className="club-description">{description}</p>
      <button className="upload-button">Upload Music</button>
    </div>
  );
};

export default ClubCard;