// src/ClubList.js
import React from 'react';
import ClubCard from './clubcard';
import '../CssFiles/clublist.css'; 

const clubs = [
  {
    logo: 'sephora.jpg',
    title: 'Club Sephora',
    description: 'Description of the Sephora club. This is where the information about the club will go.'
  },
  {
    logo: 'netflix.jpg',
    title: 'Club Netflix',
    description: 'Description of the Netflix club. This is where the information about the club will go.'
  },
  {
    logo: 'apple.png',
    title: 'Club Apple',
    description: 'Description of the Apple club. This is where the information about the club will go.'
  }
];

const ClubList = () => {
  return (
    <div className="club-list">
      {clubs.map((club, index) => (
        <ClubCard
          key={index}
          logo={club.logo}
          title={club.title}
          description={club.description}
        />
      ))}
    </div>
  );
};

export default ClubList;