import React, { useState } from "react";
import "../CssFiles/dashboard.css";
import RegisterSong from "./songRegister";
import SongList from "./songList";
import ProfileContainer from "./profil";
import Sidebar from "./Sidebar";
import AppBar from "./appbar";
import Club from "./club";
import Royalties from "./royalties";
import AddArticle from "./AddArticle";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";

import "../CssFiles/AddArticle.css";
import { firestore } from "../firebase.js"; // Make sure the path is correct
import { collection, addDoc } from "firebase/firestore";
import ClubList from "./clublist.js";

const Dashboard = ({}) => {
  const [view, setView] = useState("catalog");
  const [showRegister, setShowRegister] = useState(false);
  const [showList, setShowList] = useState(true);
  const { isAuthenticated } = useAuth();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  const handleClickRegister = () => {
    setShowRegister(true);
    setShowList(false);
  };

  const handleClickList = () => {
    setShowRegister(false);
    setShowList(true);
  };

  const handleNavigate = (view) => {
    setView(view);
  };



  return (
    <>
      <AppBar />
      <div className="dashboard-container">
        <Sidebar onNavigate={handleNavigate} />{" "}
        <div className="content">
          {view === "catalog" && <SongList />}
          {view === "profile" && (
            <>
              <ProfileContainer />
            </>
          )}
          {view === "theclub" && <ClubList />}
          {view === "royalties" && <Royalties />}
        </div>
      </div>
    </>
  );
};

export default Dashboard;