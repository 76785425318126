import React, { useEffect, useState } from "react";
import firebase from "../firebase";
import "../CssFiles/songList.css";
import SongDetail from "./songsDetail";

function AdminPage() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSongId, setSelectedSongId] = useState(null);
  const [editingSongId, setEditingSongId] = useState(null);
  const [updatedSongTitle, setUpdatedSongTitle] = useState("");
  const [selectedSortOption, setSelectedSortOption] = useState("");

  const auth = firebase.auth();
  const user = auth.currentUser;

  useEffect(() => {
    if (user) {
      getSongs();
    } else {
      setList([]);
    }
  }, [user]);

  function getSongs() {
    setLoading(true);
    const songsCollection = firebase.firestore().collection("Songs");

    songsCollection.onSnapshot((querySnapshot) => {
      const songs = [];
      querySnapshot.forEach((doc) => {
        const songData = doc.data();
        const userId = songData.userId;
        const song = { id: doc.id, userId, ...songData };
        songs.push(song);
      });
      setList(songs);
      setLoading(false);
    });
  }

  async function deleteSong(songId) {
    try {
      await firebase.firestore().collection("Songs").doc(songId).delete();
      console.log("Song deleted successfully");
    } catch (error) {
      console.error("Error deleting song:", error);
    }
  }

  function handleSearch(event) {
    setSearchQuery(event.target.value);
  }

  function handleSongClick(songId) {
    setSelectedSongId(songId);
  }

  function handleEdit(songId, songTitle) {
    setEditingSongId(songId);
    setUpdatedSongTitle(songTitle);
  }

  function handleUpdate() {
    const userSongsCollection = firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("Songs")
      .doc(editingSongId);

    userSongsCollection.update({
      Maintitle: updatedSongTitle,
    });

    setEditingSongId(null);
    setUpdatedSongTitle("");
  }

  const filteredList = list.filter((song) =>
    song.Maintitle.toLowerCase().includes(searchQuery.toLowerCase())
  );

  function handleSortAlphabetically() {
    if (selectedSortOption === "alphabetical") {
      const sortedList = [...list].sort((a, b) =>
        a.Maintitle.localeCompare(b.Maintitle)
      );
      setList(sortedList);
    }
  }

  function handleSortByLastTitle() {
    if (selectedSortOption === "lastTitle") {
      const sortedList = [...list].sort((a, b) => b.createdAt - a.createdAt);
      setList(sortedList);
    }
  }

  function handleSortOptionChange(event) {
    setSelectedSortOption(event.target.value);
  }

  if (loading) {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="songListContainer">
      <div className="header">
        <h2 className="heading">Song List</h2>
        <div className="sortContainer">
          <select
            value={selectedSortOption}
            onChange={handleSortOptionChange}
            className="sortDropdown"
          >
            <option value="">-- Select your order--</option>
            <option value="alphabetical">Alphabetical order</option>
            <option value="```jsx
lastTitle">Latest song</option>
          </select>
          <button
            className="sortButton"
            onClick={
              selectedSortOption === "lastTitle"
                ? handleSortByLastTitle
                : handleSortAlphabetically
            }
          >
            Order
          </button>
        </div>
        <input
          className="searchInput"
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <div className="songTableContainer">
        <table className="songTable">
          <thead>
            <tr>
              <th id="songtitre">Song Title</th>
              <th id="songuser">User</th>
              <th id="songdelete">Delete</th>
            </tr>
          </thead>
          <tbody>
            {filteredList.map((song) => (
              <tr
                key={song.id}
                className={`songRow ${
                  selectedSongId === song.id ? "selected" : ""
                }`}
                onClick={() => handleSongClick(song.id)}
              >
                <td>
                  {editingSongId === song.id ? (
                    <input
                      id="updatesong"
                      type="text"
                      value={updatedSongTitle}
                      onChange={(e) => setUpdatedSongTitle(e.target.value)}
                    />
                  ) : (
                    song.Maintitle
                  )}
                </td>
                <td>{song.userId}</td>
                <td>
                  <button
                    className="deleteButton"
                    onClick={() => deleteSong(song.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminPage;
