import React from 'react';
import {  pdfjs } from 'react-pdf';
import { saveAs } from 'file-saver';
import Terms_of_Service_and_Use_Tweny from '../pdf/Terms_of_Service_and_Use_Tweny.pdf'; // Update the path to your terms PDF file
import { useNavigate } from "react-router-dom";



pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Services = () => {
  const downloadPDF = async () => {
    try {
      const response = await fetch(Terms_of_Service_and_Use_Tweny);
      const blob = await response.blob();
      saveAs(blob, 'terms.pdf');
    } catch (error) {
      console.error('Error downloading terms PDF:', error);
    }
  };
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };

  return (
    <>
    <a onClick={handleNavigate} href="/">
        <img className="logo-image" src="../logo.png" alt="Logo" />
      </a>
      <video className="contact" autoPlay loop muted>
        <source src="/Videos/tweny.mp4" type="video/mp4" />
      </video>
      <div className="centered-div">
        <h1 className="white-text">TERMS OF SERVICE AND USE</h1>
        <button onClick={downloadPDF}>Download TERMS OF SERVICE AND USE PDF</button>
    </div>
    </>
   
  );
};

export default Services;
