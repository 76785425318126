import React from 'react';
import '../CssFiles/Payment.css';
import PricingTable from './pricingtable';

function Payment() {
  return (
    <div className='payment-container'>
      <h3>Select Your Plan</h3>
      <PricingTable />
    </div>
  );
}

export default Payment;
