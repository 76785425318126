import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import SocialFollow from './SocialFollow';

export default function Footer() {
  return (
    <MDBFooter bgColor='white' className='text-center  text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div>
          <SocialFollow/>
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                tweny
              </h6>
              <p>
                Tweny, the platform for artists to protect, sync and collect rights. 
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                London, England
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                <a href="mailto:hello@twenymusic.com">hello@twenymusic.com</a>
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> <a href="tel:+33695223382">+33 6 95 22 33 82</a>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </MDBFooter>
  );
}
