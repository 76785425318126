import "./CssFiles/App.css";
import React from "react";
import Register from "./Components/register";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./Components/dashboard";
import PasswordRecovert from "./Components/passwordRecover";
import CreateCount from "./Components/createcount";
import Terms from "./Components/terms";
import Services from "./Components/terms_services_use";
import Profile from "./Components/profil";
import Contacts from "./Components/contacts";
import { HelmetProvider } from "react-helmet-async";
import AdminPage from "./Components/adminPage";
import HomePage from "./Components/homepage";
import RegisterSong from "./Components/songRegister";
import RegisterSongs from "./Components/registermanysongs";
import Payment from "./Components/Payment";
import Completion from "./Components/Completion";
import AddArticle from "./Components/AddArticle";
import Article from "./Components/Article";
import { useState } from "react";
import Carousel from "./Components/Carousel";
import SlickCarousel from "./Components/SlickCarousel";
import { AuthProvider } from "./Components/AuthContext";
import EmailVerification from "./Components/emailVerification";

function App() {
  const [currentArticle, setCurrentArticle] = useState(null);
  const [articles, setArticles] = useState([]);

  const addNewArticle = (article) => {
    setCurrentArticle(article);
    setArticles([...articles, article]);
  };
  return (
    <>
      <HelmetProvider>
        <div className="App-header">
          <AuthProvider>
            <Router>
              <Routes>
                <Route path="/" element={<HomePage articles={articles} />} />
                <Route path="/register" element={<Register />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/services" element={<Services />} />
                <Route path="/signup" element={<CreateCount />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/profil" element={<Profile />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route
                  path="/password-recover"
                  element={<PasswordRecovert />}
                />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/registeronesong" element={<RegisterSong />} />
                <Route path="/registermanysongs" element={<RegisterSongs />} />
                <Route path="/payment" element={<Payment />} />
                <Route path="/completion" element={<Completion />} />
                <Route
                  path="/addarticle"
                  element={<AddArticle onAddArticle={addNewArticle} />}
                />
                <Route
                  path="/article"
                  element={
                    currentArticle && (
                      <Article
                        title={currentArticle.title}
                        content={currentArticle.content}
                      />
                    )
                  }
                />
                {/* <Route
                path="/carousel"
                element={<Carousel articles={articles} />}
              /> */}
                {/* <Route path="/slickcarousel" element={<SlickCarousel />} /> */}
                <Route
                  path="/email-verification"
                  element={<EmailVerification />}
                />
              </Routes>
            </Router>
          </AuthProvider>
        </div>
      </HelmetProvider>
    </>
  );
}

export default App;
