// Import the functions you need from the SDKs you need

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Add this import



const firebaseConfig = {
  apiKey: "AIzaSyBhiSTYseroc5-ekpCc2lTHnyLJROA0N8A",
  authDomain: "tweny-813f8.firebaseapp.com",
  projectId: "tweny-813f8",
  storageBucket: "tweny-813f8.appspot.com",
  messagingSenderId: "100083514567",
  appId: "1:100083514567:web:a0bf27fbb8b9115dbe92e2",
  measurementId: "G-F01ME708LF"
};



firebase.initializeApp(firebaseConfig);
const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const firestore = getFirestore(app);
const storage = getStorage(app);


// Set persistence mode to "none"
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export { app, firestore, auth, storage };
export default firebase;
