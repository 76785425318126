import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../CssFiles/caroussels.css'; // Import the CSS file you created

function Carouselles() {

  return (
    <Carousel className="w-50">
      <Carousel.Item>
        <video
          className="d-block w-75 carousel-video" // Add carousel-video class to the video element
          src="/Videos/twenyvideo.mp4"
          controls
        />
        <Carousel.Caption>
          {/* Add any caption text here if needed */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-75"
          src="/images/article2.jpeg"
          alt="Second slide"
        />
        <Carousel.Caption>
          {/* Add any caption text here if needed */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <video
          className="d-block w-75 carousele-video" // Add carousel-video class to the video element
          src="/Videos/newsync.mp4"
          controls
        />
        <Carousel.Caption>
          {/* Add any caption text here if needed */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Carouselles;
