// api.js
import axios from 'axios';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';


// Spotify Auth
export const getSpotifyAuthURL = () => {
  const clientId = 'e5430f85f61b45279d9be2f96d452cc9';
  const redirectUri = 'https://twenymusic.com';
  const scope = 'user-read-private user-read-email';
  return `https://accounts.spotify.com/authorize?response_type=token&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=spotify`;
};

export const getSpotifyFollowers = async (accessToken) => {
  try {
    const response = await axios.get('https://api.spotify.com/v1/me', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data.followers.total;
  } catch (error) {
    console.error('Error fetching Spotify followers:', error);
    return 0;
  }
};

// YouTube Auth
export const getYouTubeAuthURL = () => {
  const clientId = '100083514567-u0p19clogkpskvqlmndmqc6troevkl6r.apps.googleusercontent.com';
  const redirectUri = 'https://twenymusic.com/dashboard';
  const scope = 'https://www.googleapis.com/auth/youtube.readonly';
  return `https://accounts.google.com/o/oauth2/auth?response_type=token&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=youtube`;
};

export const getYouTubeSubscribers = async (accessToken) => {
  try {
    const response = await axios.get('https://www.googleapis.com/youtube/v3/channels', {
      params: {
        part: 'statistics',
        mine: true,
        access_token: accessToken,
      },
    });
    const channel = response.data.items[0];
    return channel.statistics.subscriberCount;
  } catch (error) {
    console.error('Error fetching YouTube subscribers:', error);
    return 0;
  }
};

// Update Firebase
export const updateUserInFirestore = async (uid, data) => {
  const firestore = getFirestore();
  const userDocRef = doc(firestore, 'users', uid);
  try {
    await updateDoc(userDocRef, data);
  } catch (error) {
    console.error('Error updating user in Firestore:', error);
  }
};