import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import '../CssFiles/fpassword.css';
import '../CssFiles/register.css';
import { useNavigate } from 'react-router-dom';

export default function PasswordRecovery() {
  const [email, setEmail] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [notification, setNotification] = useState('');

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/');
  };

  const handleReset = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setResetSent(true);
        setNotification('Password reset link sent to your email address. Please check your inbox.');

        // Navigate to a different route after resetting password
        setTimeout(() => {
          navigate('/');
        }, 3000); // Delay the navigation for 3 seconds (adjust as needed)
      })
      .catch((error) => {
        console.error('Reset password error', error);
      });
  };

  return (
    <>
    <video className="back" autoPlay loop muted playsInline>
      <source src="/Videos/tweny.mp4" type="video/mp4" />
    </video>
          
      <div className="passwordrecover">
        <h1 className="Title">
          <a onClick={handleNavigate} href="/register">
            <img className="logo-image" src="../logo.png" alt="Logo" />
          </a>
        </h1>
        {notification && <p className="notification">{notification}</p>}
        {resetSent ? (
          <p className="recover">Password reset link sent to your email address. Please check your inbox.</p>
        ) : (
          <div className="inputgroup">
            <input
              type="email"
              className="inpute"
              id="Email"
              name="Email"
              placeholder="Enter your email"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="button--submit" onClick={() => { handleReset(); handleNavigate(); }}>
              Reset Password
            </button>
          </div>
        )}
      </div>
     
    </>
  );
}
