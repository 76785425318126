import React from "react";
import "../CssFiles/registermanysongs.css";
import DownloadFile from "./downloadFiles";
import { useNavigate } from "react-router-dom";


function RegisterSongs(){

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/dashboard");
  };
  return(
    <>
      <video className="back" autoPlay loop muted playsInline>
        <source src="/Videos/tweny.mp4" type="video/mp4" />
      </video>
      <h1 className="Title">
        <a onClick={handleNavigate} href="/dashboard">
          <img className="logo-manysongs" src="../logo.png" alt="Logo" />
        </a>
      </h1>
      <div class="box">
        <span></span>
        <div class="content">
          <h2><DownloadFile/></h2>
        </div>
      </div>
    </>
  )
}
export default RegisterSongs;