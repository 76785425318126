import React from 'react';
import "../CssFiles/contacts.css";
import { useNavigate } from "react-router-dom";
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import LogOut from './logOut';




export default function Contacts() {

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/dashboard");
  };

  return (
    <>
       <a onClick={handleNavigate} href="/dashboard">
        <img className="logo" src="../logo.png" alt="Logo" />
      </a>
      <video className="contact" autoPlay loop muted>
        <source src="/Videos/tweny.mp4" type="video/mp4" />
      </video>
      <LogOut/>
    <MDBFooter bgColor='light' className='text-center text-lg-left'>
      <MDBContainer className='p-4'>
        <MDBRow>
          <MDBCol lg='4' md='1' className='mb-4 mb-md-0'>
            <h5 className='text-uppercase'>tweny</h5>

            <p>
              We protect, sync and collect your creations
              tweny makes it easy for music creators to deposit instantlythrough major PRO’s.
              Efficiently manage the recovery of rights. Boost your sync catalog globally,and getpaid faster.
              We make all this happen in a single place through the tweny dashboard
              </p>
          </MDBCol>

          <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon color='secondary' icon='home' className='me-2' />
                128 City Road, London, England GB
              </p>
              <p>
                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                hello@twenymusic.com
              </p>
            
            </MDBCol>
        </MDBRow>
      </MDBContainer>

    
    </MDBFooter>
    </>
  );
}
