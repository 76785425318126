import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const Sidebar = ({ onNavigate }) => {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const fetchUserData = async (userId) => {
      const userDoc = await getDoc(doc(db, "users", userId));
      if (userDoc.exists()) {
        setAuthUser({
          name: userDoc.data().username || 'User',
          lastname: userDoc.data().userlastname || 'Last',
          email: userDoc.data().email,
        });
      }
    };

    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        fetchUserData(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="sidebar">
      <br />
      <br />
      {authUser ? (
        <h1>{authUser.name} <br/>{authUser.lastname} <span>&#128274;</span></h1>
      ) : (
        <h1>Loading...</h1>
      )}
      <ul>
        <li onClick={() => onNavigate('catalog')}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12 2a10 10 0 100 20 10 10 0 000-20zM12 18a6 6 0 110-12 6 6 0 010 12zm1-7h-2v5h2V11zm0-4h-2v2h2V7z"/>
          </svg>
          <a >Catalog</a>
        </li>
        <li onClick={() => onNavigate('theclub')}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
          </svg>
          <a >The Club</a>
        </li>
        <li onClick={() => onNavigate('royalties')}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17.93c-2.51.46-5.11-.37-6.93-2.2-1.81-1.81-2.63-4.42-2.18-6.94.45-2.51 2.12-4.69 4.44-5.45 1.11-.36 2.29-.38 3.4-.06 1.1.32 2.1 1.02 2.83 1.87.72.85 1.16 1.89 1.25 3.03.09 1.14-.18 2.28-.76 3.27-.58.99-1.45 1.83-2.57 2.28z"/>
          </svg>
          <a >Royalties</a>
        </li>
        <li onClick={() => onNavigate('profile')}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12 12a5 5 0 100-10 5 5 0 000 10zM12 14c-5.33 0-8 2.67-8 8h16c0-5.33-2.67-8-8-8z"/>
          </svg>
          <a >Profile</a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;