import React, { useState } from "react";
import "../CssFiles/EditSongPopup.css"; 

function EditSongPopup({ song, onSave, onClose }) {
  const [title, setTitle] = useState(song["Song Title"]);
  const [songwriter, setSongwriter] = useState(song.username);

  const handleSave = () => {
    onSave(song.id, { "Song Title": title, username: songwriter });
    onClose();
  };

  return (
    <div className="popup-overlay2">
      <div className="popup-content2">
        <h2>Edit Song</h2>
        <div className="formgroup">
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="formgroup">
          <label>Songwriter(s)</label>
          <input
            type="text"
            value={songwriter}
            onChange={(e) => setSongwriter(e.target.value)}
          />
        </div>
        <button id="boutoneditpop" onClick={handleSave}>Save Changes</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}

export default EditSongPopup;