import React, { useEffect, useState } from 'react';
import firebase from '../firebase';
import '../CssFiles/royalties.css';

const Royalties = () => {
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [totalRoyalties, setTotalRoyalties] = useState(0);
  const [sortOrder, setSortOrder] = useState([true, true, true, true]);
  const [filteredSongs, setFilteredSongs] = useState([]);

  useEffect(() => {
    const unsubscribeAuth = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        getSongs(user.uid);
      } else {
        setSongs([]);
        setLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  useEffect(() => {
    setFilteredSongs(songs);
  }, [songs]);

  const getSongs = (userId) => {
    setLoading(true);
    const userSongsCollection = firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .collection('Songs');

    const userDocRef = firebase.firestore().collection('users').doc(userId);
    userDocRef
      .get()
      .then((userDocSnapshot) => {
        if (userDocSnapshot.exists) {
          const userData = userDocSnapshot.data();
          setUsername(userData.username);

          userSongsCollection.onSnapshot((querySnapshot) => {
            const songs = [];
            let total = 0;
            querySnapshot.forEach((doc) => {
              const songData = doc.data();
              const song = {
                id: doc.id,
                ...songData,
                createdAt: songData.createdAt ? songData.createdAt.toDate() : new Date(),
                username: userData.username,
              };
              songs.push(song);
              if (songData.royalamount) {
                total += parseFloat(songData.royalamount.replace('$', ''));
              }
            });
            setSongs(songs);
            setTotalRoyalties(total);
            setLoading(false);
          });
        } else {
          console.error('User data not found');
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error getting user data:', error);
        setLoading(false);
      });
  };

  const sortTable = (n) => {
    const sortedList = [...filteredSongs].sort((a, b) => {
      const x = getColumnValue(a, n);
      const y = getColumnValue(b, n);
      if (n === 3) {
        const dateX = a.paymentdate ? new Date(a.paymentdate) : new Date();
        const dateY = b.paymentdate ? new Date(b.paymentdate) : new Date();
        return sortOrder[n] ? dateX - dateY : dateY - dateX;
      } else if (n === 2) {
        const amountX = a.royalamount ? parseFloat(a.royalamount.replace('$', '')) : 0;
        const amountY = b.royalamount ? parseFloat(b.royalamount.replace('$', '')) : 0;
        return sortOrder[n] ? amountX - amountY : amountY - amountX;
      } else {
        if (x < y) return sortOrder[n] ? -1 : 1;
        if (x > y) return sortOrder[n] ? 1 : -1;
        return 0;
      }
    });
    setSortOrder((prevSortOrder) => {
      const newSortOrder = [...prevSortOrder];
      newSortOrder[n] = !newSortOrder[n];
      return newSortOrder;
    });
    setFilteredSongs(sortedList);
  };

  const getColumnValue = (song, index) => {
    switch (index) {
      case 0:
        return song.username ? song.username.toLowerCase() : '';
      case 1:
        return song['Song Title'] ? song['Song Title'].toLowerCase() : '';
      case 2:
        return song.royalamount ? parseFloat(song.royalamount.replace('$', '')) : 0;
      case 3:
        return song.paymentdate ? new Date(song.paymentdate) : new Date();
      default:
        return '';
    }
  };

  if (loading) {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="royalties-container">
      <h1>Royalties</h1>
      <p>${totalRoyalties.toFixed(2)} total royalties paid to {username}</p>
      <table className="royalties-table">
        <thead>
          <tr>
            <th onClick={() => sortTable(0)}>
              Artist Name <span className="sort-icon"></span>
            </th>
            <th onClick={() => sortTable(1)}>
              Song Title <span className="sort-icon"></span>
            </th>
            <th onClick={() => sortTable(2)}>
              Royalty Amount <span className="sort-icon"></span>
            </th>
            <th onClick={() => sortTable(3)}>
              Payment Date <span className="sort-icon"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredSongs.map((song, index) => (
            <tr key={index}>
              <td>{song.username}</td>
              <td className="song-title">{song['Song Title']}</td>
              <td>{song.royalamount ? song.royalamount : 'N/D'}</td>
              <td className="pdate" >{song.paymentdate ? song.paymentdate : 'N/D'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Royalties;