import React from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import * as XLSX from 'xlsx';
import axios from "axios";
import "../CssFiles/dl.css";

const db = firebase.firestore();

const DownloadFile = () => {
  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const content = e.target.result;
          const workbook = XLSX.read(content, { type: 'binary' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          const songsData = [];
          const header = data[0];
          for (let i = 1; i < data.length; i++) {
            const song = {};
            for (let j = 0; j < header.length; j++) {
              const key = header[j];
              const value = data[i][j];
              song[key] = value;
            }

            // Add the "createdAt" field with the current timestamp
            song.createdAt = firebase.firestore.FieldValue.serverTimestamp();

            songsData.push(song);
          }

          const user = firebase.auth().currentUser;
          if (user) {
            const userSongsCollection = db.collection('users').doc(user.uid).collection('Songs');
            for (const songData of songsData) {
              await userSongsCollection.add(songData);
            }

            // Send data to Jotform API
            const jotformFormID = "231802016582348"; // Replace with your Jotform form ID
            const jotformAPIKey = "15bec2bde31aec7dd33b48f7624c454f"; // Replace with your Jotform API key

            for (const songData of songsData) {
              try {
                const formData = new FormData();
                for (const key in songData) {
                  formData.append(`submission[data[${key}]]`, songData[key]);
                }

                await axios({
                  method: "post",
                  url: `https://api.jotform.com/form/${jotformFormID}/submissions`,
                  headers: {
                    "APIKEY": jotformAPIKey,
                  },
                  data: formData,
                });

                console.log("Jotform submission success!");
              } catch (error) {
                console.error("Error submitting data to Jotform:", error);
              }
            }

            alert('Data successfully saved and submitted to Jotform');

            // Redirect to the dashboard after successful processing
            window.location.href = '/dashboard'; // Replace '/dashboard' with the actual URL of your dashboard page
          } else {
            console.error('User not logged in.');
          }
        };
        fileReader.readAsBinaryString(file);
      } catch (error) {
        console.error('Error reading file:', error);
      }
    }
  };

  return (
    <div className="file-input">
      <input type="file" id="file" className="file" onChange={handleFileChange} />
      <label htmlFor="file">
        Select file
        <p className="file-name" />
      </label>
      <a className="modele" href={process.env.PUBLIC_URL + '/tweny.xlsx'} download>See the Model File</a>
      <p className="model">If you have a lot of songs in an excel file,
         you can download it here</p>
    </div>
  );
};

export default DownloadFile;
