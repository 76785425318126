import React, { useState } from "react";

const AgreementForm = () => {
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [dataConsent, setDataConsent] = useState(true);
  const [cookieConsent, setCookieConsent] = useState(true);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    switch (name) {
      case "terms":
        setTermsAgreed(checked);
        break;
      case "data":
        setDataConsent(checked);
        break;
      case "cookie":
        setCookieConsent(checked);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div>
        <label>
          <input
            type="checkbox"
            name="terms"
            checked={termsAgreed}
            onChange={handleCheckboxChange}
          />
          <span>
            &nbsp;I agree to the&nbsp;
            <a href="/terms" target="_blank" rel="noopener noreferrer">
              terms and conditions
            </a>
            &nbsp;stated in the General Terms of Use.
          </span>
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            name="data"
            checked={dataConsent}
            onChange={handleCheckboxChange}
          />
          <span>
            &nbsp;I consent to the collection and processing of my personal data
            as outlined in the&nbsp;
            <a href="/services" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            .
          </span>
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            name="cookie"
            checked={cookieConsent}
            onChange={handleCheckboxChange}
          />
          <span>
            &nbsp;I accept that twenty ensures the publication of my works&nbsp;
            <a href="/cookie" target="_blank" rel="noopener noreferrer"></a>
          </span>
        </label>
      </div>
    </div>
  );
};

export default AgreementForm;
